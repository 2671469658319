import React from 'react';
import { styled } from '@glitz/react';
import BaseImage from 'Shared/Image';
import { Preset } from 'Shared/Image/Ratio';
import { black, thin, huge, pixelsToUnit, minMediumMediaQuery, small, delta, beta, kappa, sigma } from 'Shared/Style';

type PropType = {
  authorName: string;
  authorImageUrl: Scope.UrlViewModel;
  authorDescription: string;
};

export default (props: PropType) => (
  <Base>
    <ImageWrapper>
      <Image preset={Preset.Tiny} src={props.authorImageUrl.url} />
    </ImageWrapper>
    <About>
      <Name>{props.authorName}</Name>
      <Description>{props.authorDescription}</Description>
    </About>
  </Base>
);

const Base = styled.div({
  borderTop: {
    width: thin,
    style: 'dashed',
    color: black,
  },
  borderBottom: {
    width: thin,
    style: 'dashed',
    color: black,
  },
  display: 'flex',
  justifyContent: 'center',
  marginTop: `calc(2 * ${huge})`,
  paddingTop: huge,
  paddingBottom: huge,
});

const Image = styled(BaseImage, {
  borderRadius: '50%',
  border: {
    xy: {
      width: thin,
      style: 'solid',
      color: black,
    },
  },
  flex: {
    basis: pixelsToUnit(80),
    grow: 0.25,
  },
  [minMediumMediaQuery]: {
    minWidth: pixelsToUnit(80),
    maxWidth: pixelsToUnit(120),
  },
});

const ImageWrapper = styled.div({
  height: pixelsToUnit(120),
  width: pixelsToUnit(120),
  marginRight: small,
  [minMediumMediaQuery]: {
    marginRight: huge,
  },
});

const About = styled.div({
  display: 'flex',
  flex: {
    basis: pixelsToUnit(189),
    direction: 'column',
    grow: 0.25,
  },
  justifyContent: 'center',
  marginLeft: 0,
  minWidth: pixelsToUnit(189),
  textAlign: 'left',
});

const Name = styled.div({
  fontSize: delta,
  letterSpacing: pixelsToUnit(2.1),
  textTransform: 'uppercase',
  [minMediumMediaQuery]: {
    fontSize: beta,
    letterSpacing: pixelsToUnit(3),
  },
});

const Description = styled.div({
  fontSize: kappa,
  lineHeight: 'normal',
  [minMediumMediaQuery]: {
    fontSize: sigma,
  },
});
